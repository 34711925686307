/** DO NOT MODIFY THIS FILE IS OVERWRITTEN VIA Saguaro CLI */
export const CONFIG = {
    "online": true,
    "debug": false,
    "demo": false,
    "webapp": true,
    "dev_url": "",
    "sitename": "assetgo",
    "sitename_uppercase": "AssetGo",
    "version": "4.4.8",
    "short_version": "448",
    "build_timestamp": 1734348020771,
    "testing": false,
    "staging": false,
    "sentry": true
};